<template>
  <div class="sideBar px-3 box-shadow bg-white">
    <button @click="resetFilter" class="btn primary-button text-white py-1">
      Clear all filters
    </button>
    <h6 class="my-3 secondaryColor border-bottom pb-3">Filters</h6>
    <!-- {{employees.data}} -->
    <div v-if="!isTeamMember()">
      <p class="f-16 my-2">View As</p>
      <div class="position-relative border-bottom pb-3">
        <select
          v-model="sideBarData.viewAs"
          id="shift_name"
          class="form-select mt-2 shadow-none bg-light border-0"
        >
          <option value="calendar">Calendar</option>
          <option value="employee">Employee</option>
          <option value="vacant_employee">Vacant Employee</option>
        </select>
      </div>
    </div>
 
  <!-- <div
      v-if="sideBarData.viewAs == 'calendar' && !showCalander"
      class="py-3 border-bottom"
    >
      <p class="f-16 mb-2">Select Range</p>
      <date-range-picker
        class="bg-light w-100"
        ref="picker"
        :opens="'right'"
        :locale-data="{ format: 'mm/dd/yyyy' }"
        :singleDatePicker="false"
        :timePicker="false"
        :timePicker24Hour="false"
        :showWeekNumbers="true"
        :showDropdowns="true"
        :autoApply="false"
        v-model="date_range"
      >
        <template v-slot:input="picker" style="min-width: 350px">
          {{ picker.startDate | date }} - {{ picker.endDate | date }}
        </template>
      </date-range-picker>
    </div> -->
    <div class="calendar-input mb-2" v-if="showCalander">
      <vc-calendar
        :attributes="attrs"
        title-position="left"
        @dayclick="onDayClick"
      />
    </div>
    <div id="faqs-accordion" class="custom-accordion mt-2">
      <template
        v-if="!isTeamMember() && sideBarData.viewAs != 'vacant_employee' && $route.name == 'calendar'"
      >
        <!-- Availibity Section -->
         <div class="availability border-bottom border-2 py-2">
            <p class="f-14 mb-2 fw-bold">Availability</p>
            <div v-for="(availability , i) in availabilities" :key="i">
              <div class="form-check form-switch mb-2" >
                <input
                  class="form-check-input"
                  v-model="sideBarData.availability"
                  type="radio" 
                  :value="availability.value" 
                  :id="availability.value"
                />
                <label class="form-check-label fw-normal" for="fullTime"
                  >{{availability.label}}</label
                >
              </div>
            </div>
          </div>
        <!--end of Availibity Section -->

        <div class="card shadow-none border-bottom pb-2 py-3">
          <a
            href="javascript: void(0);"
            v-b-toggle.faqs-gen-ques-empl
            class="text-dark"
            data-toggle="collapse"
            aria-expanded="true"
            aria-controls="faqs-gen-ques-empl"
          >
            <!-- {{sideBarData.employee}} -->
            <div class="border-0">
              <div class="media align-items-center">
                <div class="media-body overflow-hidden">
                  <h5 class="font-size-14 fw-medium mb-1 fw-bold">Employees</h5>
                </div>
                <i class="mdi mdi-chevron-up accor-down-icon font-size-16"></i>
              </div>
            </div>
          </a>

          <b-collapse
            id="faqs-gen-ques-empl"
            visible
            accordion="faqs-accordion"
            data-parent="#faqs-accordion"
          >
            <div class="">
              <vue-multiselect
                placeholder="Select an Employee"
                v-model="sideBarData.employee"
                :load-more="loadMoreEmployees"
                :loading="employees.loading"
                :options="employees.data"
                @on-open="fetchAllMembers"
                @on-reaching-end="fetchEmployeesOnReachingEnd"
                internal-search
                showNoResult
                :id="'employees'"
                label="name"
              >
              </vue-multiselect>
            </div>
          </b-collapse>
        </div>
      </template>

      <!-- location filter -->
      <div
        v-if="sideBarData.viewAs != 'vacant_employee'"
        class="card shadow-none border-bottom pb-2"
      >
        <a
          href="javascript: void(0);"
          v-b-toggle.faqs-gen-ques-loc
          class="text-dark"
          data-toggle="collapse"
          aria-expanded="true"
          aria-controls="faqs-gen-ques-loc"
        >
          <div class="border-0">
            <div class="media align-items-center">
              <div class="media-body overflow-hidden">
                <h5 class="font-size-14 fw-medium mb-1 fw-bold">Location</h5>
              </div>
              <i class="mdi mdi-chevron-up accor-down-icon font-size-16"></i>
            </div>
          </div>
        </a>

        <b-collapse
          id="faqs-gen-ques-loc"
          visible
          accordion="faqs-accordion"
          data-parent="#faqs-accordion"
        >
          <div class="y_scroll">
            <div>
              <div>
                <select
                  v-model="sideBarData.location"
                  class="form-select mt-2 bg-light border-0 shadow-none"
                >
                  <option value="showAll">Show All</option>
                  <option
                    v-for="(location, index) in locations"
                    :value="location"
                    :key="index"
                  >
                    {{ location.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </b-collapse>
      </div>
      <div
        v-if="sideBarData.viewAs != 'vacant_employee'"
        class="card shadow-none border-bottom pb-2"
      >
        <a
          href="javascript: void(0);"
          v-b-toggle.faqs-gen-loc
          class="text-dark"
          data-toggle="collapse"
          aria-expanded="true"
          aria-controls="faqs-gen-loc"
        >
          <div class="border-0">
            <div class="media align-items-center">
              <div class="media-body overflow-hidden">
                <h5 class="font-size-14 fw-medium mb-1 fw-bold">Work Area</h5>
              </div>
              <i class="mdi mdi-chevron-up accor-down-icon font-size-16"></i>
            </div>
          </div>
        </a>

        <b-collapse
          id="faqs-gen-loc"
          visible
          accordion="faqs-accordion"
          data-parent="#faqs-accordion"
        >
          <div class="">
            <label>Select Area</label>
            <vue-multiselect
              v-model="sideBarData.area"
              hide-selected
              :load-more="loadMoreAreas"
              :loading="areas.loading"
              :options="areas.data"
              @on-open="fetchAreasOnByRole"
              @on-reaching-end="fetchAreasOnReachingEnd"
              :id="'areas'"
              label="name"
              internal-search
              showNoResult
            >
            </vue-multiselect>
          </div>
        </b-collapse>
      </div>
      <div
        v-if="sideBarData.viewAs == 'vacant_employee'"
        class="card shadow-none border-bottom pb-2"
      >
        <a
          href="javascript: void(0);"
          v-b-toggle.faqs-gen-loc
          class="text-dark"
          data-toggle="collapse"
          aria-expanded="true"
          aria-controls="faqs-gen-loc"
        >
          <div class="border-0">
            <div class="media align-items-center">
              <div class="media-body overflow-hidden">
                <h5 class="font-size-14 fw-medium mb-1 fw-bold">Search By Name</h5>
              </div>
              <i class="mdi mdi-chevron-up accor-down-icon font-size-16"></i>
            </div>
          </div>
        </a>

        <b-collapse
          id="faqs-gen-loc"
          visible
          accordion="faqs-accordion"
          data-parent="#faqs-accordion"
        >
          <div class="">
            <div class="row">
              <input
                type="text"
                v-model="vacantEmployeeName"
                class="form-control"
                id=""
              />
              <SubmitButton
                class="mt-2"
                text="Search"
                @clicked="searchByName()"
              />
            </div>
            <!-- <vue-multiselect
              v-model="sideBarData.area"
              hide-selected
              :load-more="loadMoreAreas"
              :loading="areas.loading"
              :options="areas.data"
              @on-open="fetchAreas"
              @on-reaching-end="fetchAreasOnReachingEnd"
              :id="'areas'"
              label="name"
            >
            </vue-multiselect> -->
          </div>
        </b-collapse>
      </div>
      <div
        v-if="sideBarData.viewAs != 'vacant_employee'"
        class="card shadow-none border-bottom pb-2"
      >
        <a
          href="javascript: void(0);"
          v-b-toggle.faqs-gen-roles
          class="text-dark"
          data-toggle="collapse"
          aria-expanded="true"
          aria-controls="faqs-gen-roles"
        >
          <div class="border-0">
            <div class="media align-items-center">
              <div class="media-body overflow-hidden">
                <h5 class="font-size-14 fw-medium mb-1 fw-bold">Roles</h5>
              </div>
              <i class="mdi mdi-chevron-up accor-down-icon font-size-16"></i>
            </div>
          </div>
        </a>

        <b-collapse
          id="faqs-gen-roles"
          visible
          accordion="faqs-accordion"
          data-parent="#faqs-accordion"
        >
          <vue-multiselect
            v-model="sideBarData.role"
            hide-selected
            :load-more="loadMoreAreas"
            :loading="roles.loading"
            :options="roles.data"
            @on-open="fetchAreasOnByRole"
            internal-search
            showNoResult
            @on-reaching-end="fetchAreasOnReachingEnd"
            :id="'roles'"
            label="name"
          >
          </vue-multiselect>
        </b-collapse>
      </div>
      <div
        v-if="sideBarData.viewAs != 'vacant_employee'"
        class="card shadow-none"
      >
         <!-- shifts name Section -->
         <div class="border-bottom border-2 py-2">
            <p class="f-14 mb-2 fw-bold">Day Part</p>
            <div v-for="(shift_name, index) in shift_names" :key="index">
              <div class="form-check form-switch mb-2" >
                <input
                  class="form-check-input"
                  v-model="sideBarData.shift_name"
                  type="radio" 
                  :value="shift_name.name" 
                  :id="shift_name.name"
                />
                <label class="form-check-label fw-normal" for="fullTime"
                  >{{shift_name.name}}</label
                >
              </div>
            </div>
          </div>
        <!--end of shifts name Section -->

      </div>

      <div
        class="card shadow-none border-bottom pb-2"
        v-if="!isTeamMember() && sideBarData.viewAs == 'calendar'"
      >

        <!-- shift_status Section -->
         <div class="availability  border-2 py-2">
            <p class="f-14 mb-2 fw-bold">Shift Status</p>
            <div  v-for="(status, index) in shift_status" :key="index">
              <div class="form-check form-switch mb-2" >
                <input
                  class="form-check-input"
                  v-model="sideBarData.shift_status"
                  type="radio" 
                  :value="status.value"
                  :id="status.value"
                />
                <label class="form-check-label fw-normal" for="fullTime"
                  >{{status.label}}</label
                >
              </div>
            </div>
          </div>
        <!--end of shift_status Section -->
      </div>
    </div>
  </div>
</template>
<script>
import VueMultiselect from "@/components/general/vue-multiselect";
import _ from "lodash";

import DateRangePicker from "vue2-daterange-picker";
import moment from "moment";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

const filter_Prototype = {
  viewAs: "calendar",
  location: null,
  shift_name: null,
  area: {},
  employee: {},
  role: {},
  availability: null,
  shift_status: null,
  vacantEmployeeName:null,
};
export default {
  components: {
    VueMultiselect,
    // DateRangePicker,
  },

  data() {
    return {
      vacantEmployeeName: null,
      selectedDay: null,
      showCalander: false,
      fullCalender: new Date(),
      size: 3,
      areas: {
        data: [],
        selected: [],
        VueMultiselectOpened: false,
        loading: false,
        nextPageUrl: null,
      },
      employees: {
        data: [],
        selected: [],
        VueMultiselectOpened: false,
        loading: false,
        nextPageUrl: null,
      },
      roles: {
        data: [],
        selected: [],
        VueMultiselectOpened: false,
        loading: false,
        nextPageUrl: null,
      },
      params: {},
      locations: [],
      location: "showAll",

      shift_names: [],

      shift_status: [
        { value: 'All', label: 'Show All' },
        { value: 'vacant_shifts', label: 'Vacant' },
        { value: 'schedule_shifts', label: 'Schedule' },
        { value: 'Pending', label: 'Pending' },
      ],

      availabilities: [
        { value: 'All', label: 'Show All' },
        { value: 'FullTime', label: 'Full Time' },
        { value: 'PartTime', label: 'Part Time' },
        { value: 'ThreeDay', label: 'Three Day' },
        { value: 'Manager', label: 'Management' },
      ],

      date_range: {
        startDate: null,
        endDate: null,
      },
      attrs: [
        {
          key: "today",
          highlight: {
            class: "date-circle", // Circle class
            contentClass: "date-text",
            rounded: 0, // Text class
          },
          dates: new Date(),
          class: "c-style",
        },
      ],
      sideBarData: {
        ...filter_Prototype,
      },
    };
  },
  computed: {
    loadMoreAreas() {
      return !!this.areas.nextPageUrl;
    },
    loadMoreEmployees() {
      return !!this.employees.nextPageUrl;
    },
  },
  watch: {
    sideBarData: {
      handler: function (newData) {
        this.$emit("filterData", newData);
        this.vacantEmployeeName = null;
      },
      deep: true,
    },

    "date_range.endDate": {
      handler: function (new_data, old_data) {
        if (new_data) {
          this.$emit("filterData", {
            startDate: this.formatDateYMD(this.date_range.startDate),
            endDate: this.formatDateYMD(this.date_range.endDate),
          });
        }
      },
    },
  },
  filters: {
    date(val) {
      return val ? moment(val).format("YYYY-MM-DD") : "";
    },
  },

  methods: {
    resetFilter() {
      this.sideBarData = {
        ...filter_Prototype,
      };
      this.date_range = {
        startDate: null,
        endDate: null,
      };
      this.$emit("filterData", { clearFilters: true });
    },

    fetchAreasOnByRole()
    { //to show the areas and roles only, at which manager were registered as an employee - on employee calendar page
      if(this.isManager() && this.$route.name == 'employee_schedule') {
        this.areas.data = [];
        this.roles.data = [];
        this.currentUser.employee.area_roles.forEach(( workarea_name)=>{ 
        let found =  this.areas.data.findIndex(area => area.id == workarea_name.area.id);
        if(found  < 0){
          this.areas.data.push(workarea_name.area);
          }
        });
        this.roles.data.push(...this.currentUser.employee.area_roles);
      }else{
        this.fetchAreas();
      }
    },

    fetchAreas() {
      let url = "/restaurant/area/role/index";
      if (this.areas.nextPageUrl) {
        url = this.areas.nextPageUrl;
      }
      this.$axios
        .get(url, { params: this.params })
        .then((response) => {
          let result = response.data.payload.paginated;
          if (url == "/restaurant/area/role/index") {
            this.areas.data = [];
            this.roles.data = [];
          }
          this.areas.data.push(...result.data);
          //add roles
          if(this.isTeamMember()){ //this will get only employee roles in filter
            this.roles.data.push(...this.currentUser.employee.area_roles);
          }else{
            result.data.forEach((element) => {
              this.roles.data.push(...element.area_role);
            });
          }
          this.areas.nextPageUrl = result.next_page_url;
        })
        .catch((error) => {
          this.handleErrorResponse(error.response, "error");
        })
        .finally(function () {});
    },

    fetchAllMembers() {
      let url = "/employee/team-member";
      if (this.employees.nextPageUrl) {
        url = this.employees.nextPageUrl;
      }
      this.$axios
        .get(url, {
          params: { employee_status: "active_employee" },
        })
        .then((response) => {
          let result = response.data.payload.paginated;
          if (url == "/employee/team-member") this.employees.data = [];
          this.employees.data.push(...result.data);
          this.employees.nextPageUrl = result.next_page_url;
        })
        .catch((error) => {
          this.handleErrorResponse(error.response, "error");
        })
        .finally(function () {});
    },

    resetData() {
      this.areas.data = [];
      this.areas.nextPageUrl = null;
    },

    // refetchTimeZones() {
    //   this.resetData();
    //   this.fetchAreas();
    // },

    // searchAreas: _.debounce(function (search) {
    //   this.resetData();
    //   this.params.search = search;
    //   this.fetchAreas();
    // }, 400),

    fetchAreasOnReachingEnd() {
      this.fetchAreas();
    },
    fetchEmployeesOnReachingEnd() {
      this.fetchAllMembers();
    },

    fetchLocations() {
      this.$axios
        .get("/restaurant/location/index", { params: { paginated: false } })
        .then((response) => {
          this.locations = response.data.payload;
        })
        .catch((error) => {
          console.log(error, "error");
        })
        .finally(function () {});
    },

    onDayClick(day) {
      let selectDate = this.customFormatDate(day.id, "YYYY-MM-DD");
      this.$emit("filterData", {
        startDate: selectDate,
        endDate: selectDate,
      });
    },
    searchByName() {
      this.$emit("searchVacantEmployee" , this.vacantEmployeeName)
      // this.sideBarData.vacantEmployeeName = this.vacantEmployeeName;
    },

    getShiftNames() {
      this.$axios.get('shift/name/index' , {params: {pagination : 0}}).then((response) => {
          let data = response.data.payload.paginated;
          this.shift_names = data;
      }).catch((error) => {
      this.handleErrorResponse(error.response, "error");
      });
    },
  },
  mounted() {
    this.fetchLocations();
    this.getShiftNames();
  },
};
</script>