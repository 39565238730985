<script>
import { stat } from "fs";
import moment, { min } from "moment";
import _ from "lodash";
import timeSlot from "@/mixins/timeSlot";
import Swal from "sweetalert2";

/**
 * Add-product component
 */
export default {
  components: {},
  mixins: [timeSlot],
  props : {
    selectedShifts : {
      type: [Array,Object],
      default: () => {},
    },
    totalshifts : {
      type : [Object],
      default: () => {},
    },
    currentLocation : {
      type: [Object],
      default : () => {},
    }
  },
  data() {
    return {
      processing: false,
      shift_names: ["All", "Breakfast", "Lunch", "Dinner"],
      create_shifts: [],
      selected_shift: null,
      isSelected: false,
      shiftTimeSlots: [],
      accept_all_modal: false,
      shift_id: null,
      shifts_group: [],
      shifts: [],
      timeline_id: null,
      current_timeline_id: null,
      area_role_id: null,
      counts: null,
      selectedTimeSlot: { from: null, to: null, type: null, slot_id: null },
      filter_by_name: "All",
      table_data: { group_by_week: true },
      moreExists: false,
      shiftsForRole: [],
      loading: false,
    };
  },
  
  methods: {
    changeTimeZone(date)
    {
      if(Object.entries(this.currentLocation).length > 0){
        let zone = this.currentLocation.utc[0];
        return this.changeDateTimeTimeZone(date,zone);
      }else{
        return this.DateWithMonthName(date , 'DD-MM-YYYY hh:mm A');
      }
    },
    
    loadmore() {
      let i = 1;
      this.shifts_group = [];
      let currentWeekNumber = moment().format("ww");
      for (const [key, value] of Object.entries(this.totalshifts["shifts"])) {
        if (
          currentWeekNumber != key ||
          parseInt(currentWeekNumber) + 1 != key
        ) {
          this.shifts_group.push({
            id: i,
            desc: key,
            name:
              currentWeekNumber == key
                ? "Available Shifts"
                : "Next Week Shifts",
            shifts: value,
          });
          i = i + 1;
        }
      }
      this.moreExists = false;
      this.$emit('weeklySelectedShifts');
    },

    shiftscard(data) {
      let i = 1;
      let checKObject = false;
      this.shifts_group = [];
      let currentWeekNumber = moment().format("ww");
      for (const [key, value] of Object.entries(data["shifts"])) {
        if (
          (i < 3 && currentWeekNumber == key) ||
          parseInt(currentWeekNumber) + 1 == key
        ) {
          this.shifts_group.push({
            id: i,
            desc: key,
            name:
              currentWeekNumber == key
                ? "Available Shifts"
                : "Next Week Shifts",
            shifts: value,
          });
          i = i + 1;
          checKObject = true;
        }
      }
    },

    checkCounts()
    {
      this.$axios
      .get("counts-stats/index", {
        params: {employee_id : this.currentUser.employee.id , shift_id: this.create_shifts[0].shift_id }
      })
      .then((response) => {
        let totalHours = response.data.payload;
        if(totalHours > 40)
        {
          this.confirmOverTimeShift();
        }else{
          this.submitShift();
        }
      }).catch(error => {
        this.handleErrorResponse(error.response, "error");
      });
    },

    confirmOverTimeShift() {
      let message =  this.currentUser.employee.minor == 1 ? "requested to schedule" : "scheduled" ;
      Swal.fire({
        title: "Are you sure?",
        text: "You have already " + message +" a 40-hour shift for this week, if you need to work an overtime shift, your request will be forwarded to management for approval.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#70C213",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes, Accept",
      }).then((result) => {
        if (result.value) {
            this.submitShift();
        }
      });
    },

    /**
     * Edit event modal submit
     */
    // eslint-disable-next-line no-unused-vars
    submitShift(e) {
      this.submit = true;

      let self = this;
      if (self.processing) {
        return;
      }
      self.processing = true;

      self.$axios
        .post("shift/employee/select-shift", this.create_shifts)
        .then((response) => {
          let data = response.data;

          self.triggerSwal(data.message, "success");
          // this.fetchSelectedShift();
          const startOfMonth = moment().startOf('month').format('YYYY-MM-DD');
          const endOfMonth   = moment().endOf('month').format('YYYY-MM-DD');
          let table_data_param= {
            from : startOfMonth,
            to : endOfMonth
          }
          this.$emit('weeklySelectedShifts');
          this.$emit('selectedShifts' , {callApi : true} , table_data_param);
          this.clearModal();
        })
        .catch((error) => {
          this.handleErrorResponse(error.response, "error");
          console.error(error);
        })
        .finally(function () {
          self.processing = false;
        });
    },

    selectShifts(e) {
      this.area_role_id = null;
      let shift = e.target._value;
      this.shift_id = shift.id;
      if (e.currentTarget.checked == true) {
        let data = {
          employee_id: this.currentUser.employee.id,
          shift_id: shift.id,
          status: "Pending",
          slot_id: null,
          slot_type: null,
        };
        this.create_shifts.push(data);

        this.selected_shift = { ...shift };
        this.shiftsForRole = shift;

        this.selected_shift.shift_time_slots = [];
        this.selected_shift.shift_time_slotsCopy = [];
        this.isSelected = true;

      } else {
        this.create_shifts = this.create_shifts.filter(
          (shft) => shft.shift_id !== shift.id
        );
        this.isSelected = false;
      }
    },

    async onChangeRole(e) {
      const slots = await this.getShiftTimeSlotsByAreaRoleId(
        this.area_role_id,
        this.shift_id
      );
      this.shiftTimeSlots = [];
      this.shiftTimeSlots = slots.data.payload;
    },
    onSelectTime(e) {
      this.create_shifts.map((shift) => {
        if (shift.shift_id == this.selected_shift.id) {
          shift.slot_id = this.selectedTimeSlot.id;
          shift.slot_type = this.selectedTimeSlot.type;
          shift.area_role_id = this.area_role_id
          return shift;
        }
      });
    },


    hoursTimeDiff(startTime, endTime) {
      var todayDate = this.getCurrentDate(); //  return in format YYYY-MM-DD
      var startDate = new Date(`${startTime}`);
      var endDate = new Date(`${endTime}`);
      var timeDiff = Math.abs(startDate.getTime() - endDate.getTime());

      var hh = Math.floor(timeDiff / 1000 / 60 / 60);
      hh = ("0" + hh).slice(-2);

      timeDiff -= hh * 1000 * 60 * 60;
      var mm = Math.floor(timeDiff / 1000 / 60);
      mm = ("0" + mm).slice(-2);

      timeDiff -= mm * 1000 * 60;
      var ss = Math.floor(timeDiff / 1000);
      ss = ("0" + ss).slice(-2);

      return  hh + ':' + mm ;
    },


    getDate(date = new Date()) {
      const d = new Date(date);
      let day = d.getDate();
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      let month = months[d.getMonth()];
      let year = d.getFullYear();
      return { day: day, month: month, year: year };
    },

    clearModal() {
      this.create_shifts = [];
      this.accept_all_modal = false;
      this.isSelected = false;
    },

    isSelectedShift(shift) {
      let status = null;
      this.selectedShifts.map((selectedShift) => {
        if (
          selectedShift.shift_id == shift.id &&
          this.currentUser.employee.id == selectedShift.employee_id
        ) {
          status = true;
        }
        //  else{
        //    status=false;
        //  }
      });

      return status;
    },

    filterArray(array) {
      let filtered_array = _.uniqBy(array, function (e) {
        return e.interval;
      });

      return filtered_array;
    },

    getDuplicate(array, interval) {
      let i = 0;
      let duplicate_array = [];
      array.map((shift) => {
        if (shift.interval == interval) {
          i = i + 1;
          if (i >= 2) {
            duplicate_array.push(shift);
          }
        }
      });
      return duplicate_array;
    },

    changeFormatTime(date)
    {
      let result = moment(date, 'DD-MM-YYYY hh:mm A').format('h:ma');
      return result;
    }
  },

  middleware: "authentication",
};
</script>

<template>
  <div>
    <APILoader
      v-if="loading"
      :loading="loading"
      class="align-middle"
    ></APILoader>
    <template v-if="shifts_group.length > 0">
      <div
        class="current-shift mb-3"
        v-for="(shift_group, index) in shifts_group"
        :key="index"
      >
        <div class="tem-title">
          <h2 class="f-20 poppins">{{ shift_group.name }}</h2>
        </div>
        <div class="card">
          <div class="card-body">
            <div
              class="
                d-flex
                flex-md-row flex-column
                justify-content-between
                align-items-center
                mb-3
                gap-3
              "
            >
              <div class="date-info">
                <h4 class="f-16 fw-bold mb-0">
                  {{ getDate(shift_group.shifts[0].shift_time_slots[0].from).month }}
                  {{ getDate(shift_group.shifts[0].shift_time_slots[0].from).year }}
                </h4>
              </div>
              <!-- <div class="carousal-box d-flex gap-2 align-items-center">
                  <div class="prev-button ">
                  <button class="btn accept-button text-white"><i class="fas fa-arrow-left"></i></button>
                  </div>
                  <div class="shift-title-wrapper">
                    <h4 class="f-16 fw-bold mb-0 pb-0">Current Shift</h4>
                  </div>
                <div class="next-button">
                    <button class="btn accept-button"><i class="fas fa-arrow-right"></i></button>

                </div>
              </div> -->
            </div>

            <hr class="bg-grey mt-2" />
            <div class="row">
              <div class="col-md-10">
                <div
                  class="calendar-grid"
                  :class="
                    shift_group.shifts.length > 3
                      ? 'calendar-grid-fit'
                      : 'calendar-grid-fill'
                  "
                >
                  <div
                    class="calendar-cell"
                    v-for="(shift, key) in filterArray(shift_group.shifts)"
                    :key="key"
                  >
                    <p class="text-center pt-lg-0 pt-3">{{ shift.interval }}</p>
                    <div
                      style="height: 100px"
                      class="
                        d-flex
                        justify-content-between
                        flex-column
                        gap-4
                        inner-cell
                        px-3
                        pt-2
                        pb-2
                        border border-top-0
                        fw-bold
                      "
                      :class="{
                        active: getDate(shift.shift_time_slots[0].from).day == new Date().getDate(),
                      }"
                    >
                      <div>{{ getDate(shift.shift_time_slots[0].from).day }}</div>
                      <div
                        class="
                          d-flex
                          justify-content-between
                          bg-primary
                          align-items-center
                          rounded
                          px-2
                        "
                      >
                        <p class="f-12 mb-0">
                          <i
                            class="fas fa-circle"
                            :style="{ color: shift.color_label }"
                          ></i>
                          <span class="text-white">{{changeFormatTime(changeTimeZone(shift.shift_time_slots[0].from))}}</span>
                        </p>
                        <p class="f-12 mb-0 text-white">{{ shift.name }}</p>
                      </div>

                      <div
                        class="see-more"
                        v-if="
                          getDuplicate(shift_group.shifts, shift.interval)
                            .length >= 1
                        "
                      >
                        <div class="hover-tooltip roboto">
                          See More
                          <div class="card tooltiptext pt-0 mt-0">
                            <div
                              class="
                                card-header
                                px-2
                                py-1
                                border-bottom
                                text-start
                              "
                            >
                              <b>
                                {{ getDate(shift.shift_time_slots[0].from).month }}
                                {{ getDate(shift.shift_time_slots[0].from).day }},
                                {{ getDate(shift.shift_time_slots[0].from).year }}</b
                              >
                            </div>
                            <div
                              v-for="(more_shift, more_key) in getDuplicate(
                                shift_group.shifts,
                                shift.interval
                              )"
                              :key="more_key"
                            >
                              <div class="card-body text-start p-2">
                                <div
                                  class="
                                    d-flex
                                    justify-content-between
                                    bg-primary
                                    align-items-center
                                    rounded
                                    px-2
                                  "
                                >
                                  <p class="f-12 mb-0">
                                    <i
                                      class="fas fa-circle"
                                      :style="{ color: more_shift.color_label }"
                                    ></i>
                                    <span class="text-white">{{changeFormatTime(changeTimeZone(more_shift.shift_time_slots[0].from))}} </span>
                                  </p>
                                  <p class="f-12 mb-0 text-white">
                                    {{ more_shift.name }}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex gap-2 justify-content-end bonus-box">
                      <span class="d-block"
                        >{{ hoursTimeDiff(shift.shift_time_slots[0].from, shift.shift_time_slots[0].to) }} Hrs</span
                      >
                      <span v-if="shift.shift_type == 'Premium'" class="d-block"
                        >Bonus</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-2">
                <p class="fw-bold">Shift Time Available</p>
                <!-- <p><span class="fw-bold">Total Hrs:</span> 2 to 22 Hrs</p> -->
                <button
                  class="btn accept-button px-4 text-white"
                  @click="shifts = shift_group.shifts"
                  v-b-modal.accept_all_modal
                >
                  Accept
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
          class="accept-button btn text-white my-3"
          v-show="moreExists"
          v-on:click="loadmore"
        >
          Load More
        </button>
    </template>

    
    <div class="create-shift-modal">
      <b-modal
        hide-footer
        id="accept_all_modal"
        v-model="accept_all_modal"
        ref="modal"
        title="Create Shift"
        size="lg"
        no-close-on-backdrop 
        no-close-on-esc 
        hide-header-close
      >
        <div class="form-box">
          <form class="">
            <div class="row">
              <div class="multiselection-grid">
                <div class="feature" v-for="(shift, key) in shifts" :key="key">
                  <input
                    type="checkbox"
                    @change="selectShifts($event)"
                    :disabled="isSelectedShift(shift)"
                    :value="shift"
                    :id="'feature' + key"
                  />
                  <div class="d-flex flex-column">
                    <span class="fw-bold d-block">
                      {{ getDate(shift.shift_time_slots[0].from).day }}
                    </span>
                    <span class="d-block"> {{ shift.interval }}</span>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div class="row" v-if="isSelected">
              <div class="d-flex gap-3 align-items-center mb-3">
                <div class="field-icon mt-4">
                  <img
                    src="@/assets/images/shifts/time-icons.png"
                    alt=""
                    width="26"
                    height="26"
                  />
                </div>
                <div class="from-time w-25">
                  <label for="role">Select Role</label>
                  <select
                    v-model="area_role_id"
                    @change="onChangeRole($event)"
                    id="role"
                    class="form-select"
                  >
                    <option
                      :value="role.id"
                      v-for="(role, key) in selected_shift.area_roles"
                      :key="key"
                    >
                      {{ role.name }}
                    </option>
                  </select>
                </div>
                <div class="from-time w-75" v-if="area_role_id != null">
                  <label for="time_slot">Select Time Slot</label>
                  <select
                    id="time_slot"
                    v-model="selectedTimeSlot"
                    class="form-select"
                    @change="onSelectTime($event)"
                  >
                    <option :value="null" disabled selected hidden > Select Shift Slot </option>
                    <option v-if="shiftTimeSlots.length == 0" disabled >No Timeslot Available</option>
                    <option
                      :value="shiftTimeBreak"
                      v-for="(shiftTimeBreak, index) in shiftTimeSlots"
                      :key="index"
                    >
                      {{ changeTimeZone(shiftTimeBreak.from) }} -
                      {{ changeTimeZone(shiftTimeBreak.to) }}
                    </option>
                  </select>
                </div>
                
              </div>
              <div class="d-flex gap-3 mb-3">
                <div class="field-icon mt-4 d-md-block d-none">
                  <img
                    src="@/assets/images/shifts/desc-icon.png"
                    alt=""
                    width="26"
                    height="26"
                  />
                </div>
                <div class="w-100">
                  <label for="">Shift Title</label>
                  <input
                    type="text"
                    v-model="selected_shift.name"
                    class="form-control"
                    readonly
                  />
                </div>
              </div>
              <div
                v-if="selected_shift.shift_type == 'Premium'"
                class="d-flex gap-3"
              >
                <div class="field-icon d-md-block d-none">
                  <img
                    src="@/assets/images/shifts/gift-icon.png"
                    alt=""
                    width="26"
                    height="26"
                  />
                </div>
                <div class="w-50">
                  <div class="input-group mb-3">
                    <span class="input-group-text">$</span>
                    <input
                      v-model="selected_shift.bonus"
                      type="text"
                      class="form-control"
                      aria-label="Amount (to the nearest dollar)"
                      readonly
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="button-group d-flex justify-content-end gap-3">
              <b-button
                class="mt-3"
                variant="outline-dark"
                block
                @click="clearModal()"
                >Cancel</b-button
              >
              <!-- <b-button
                variant="outline-white "
                class="accept-button mt-3 text-white"
                block
                @click="submitShift"
                >Select
              </b-button> -->
              <SubmitButton
                v-if="isSelected"
                class="accept-button mt-3 text-white"
                text="Create Shifts"
                @clicked="checkCounts"
                :processing="processing"
              ></SubmitButton>
            </div>
          </form>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<style scoped>
.calendar-grid-fill {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
}
.calendar-grid-fit {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(115px, 1fr));
}
</style>