export default {
    methods: {
     async getShiftTimeSlotsByAreaRoleId(area_role_id , shift_id , empty_slot = true){
           try{
          let result =  await this.$axios.get("/shift-time-slots/area-role" , {params:{ area_role_id:area_role_id , shift_id:shift_id , empty_slot:empty_slot }});
          return await result;
           }
           catch(error){
            console.error(error);

           }

        },
        getDataFromTimeline(week, timeline) {
          let self = this;
          week = week.trim().split(/\s+/);
          var res = timeline.find((obj) => {
            return self.customFormatDate(obj.from, "ddd") == week[0];
          });
          if (typeof res != "undefined") {
            return res;
          }
          return null;
        },
       
    },
   

}